import { App } from 'App'
import { StrictMode, Suspense, lazy } from 'react'
import { createRoot } from 'react-dom/client'
import './App.css'
import { kcContext as kcLoginThemeContext } from './keycloak-theme/login/kcContext'

const KcLoginThemeApp = lazy(() => import('./keycloak-theme/login/KcApp'))

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <Suspense>
      {(() => {
        if (kcLoginThemeContext !== undefined) {
          return <KcLoginThemeApp kcContext={kcLoginThemeContext} />
        }

        return <App />
      })()}
    </Suspense>
  </StrictMode>,
)
