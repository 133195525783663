import { getKcContext } from 'keycloakify/login'

export namespace SelectAuthenticator {
  export type AuthenticationSelection = {
    authenticator: string
    authExecId: string
    authenticationExecution: {
      authenticator: string
    }
  }
}
export const { kcContext } = getKcContext<
  | {
      pageId: 'login.ftl'
      redirect_uri: string
      auth: {
        authenticationSelections: SelectAuthenticator.AuthenticationSelection[]
      }
    }
  //NOTE: A 'keycloakify' field must be added
  //in the package.json to generate these pages
  | {
      pageId: 'register-login-details.ftl'
      url: {
        registrationAction: string
      }
      formData?: {
        email?: string
      }
      client: {
        attributes: {
          tosUri: string
          policyUri: string
        }
      }
      recaptchaSiteKey: string
    }
  | {
      pageId: 'register-user-details.ftl'
      url: {
        registrationAction: string
      }
      formData?: {
        firstName?: string
        lastName?: string
        phoneNumber?: string
        birthday?: string
      }
    }
  | {
      pageId: 'register-verify-sms.ftl'
      url: {
        registrationAction: string
        registrationUrl: string
      }
      code: string
    }
  | {
      pageId: 'register-verify-email.ftl'
      url: {
        registrationAction: string
        registrationUrl: string
      }
      VERIFY_EMAIL_LINK?: string
    }
  | {
      pageId: 'register-verify-email-again.ftl'
      url: {
        registrationAction: string
        registrationUrl: string
      }
      VERIFY_EMAIL_LINK?: string
    }
  | {
      pageId: 'login-verify-email.ftl'
      execution?: string
      url: {
        loginAction: string
      }
    }
  | {
      pageId: 'login-verify-sms.ftl'
      url: {
        loginAction: string
      }
      code: string
      redirect_uri: string
      remember_me_days: number
    }
  | {
      pageId: 'login-reset-password.ftl'
    }
  | {
      pageId: 'login-update-password.ftl'
      url: {
        loginAction: string
      }
    }
  | {
      pageId: 'login-page-expired.ftl'
    }
  | {
      pageId: 'logout-confirm.ftl'
    }
  | {
      pageId: 'irma-transition-user-profile.ftl'
      url: {
        registrationAction: string
        loginAction: string
      }
      client: {
        attributes: {
          tosUri: string
          policyUri: string
        }
      }
      irma_email: string
      irma_firstName: string
      irma_lastName: string
      irma_phoneNumber: string
      irma_birthday: string
    }
  | {
      pageId: 'irma-transition-verify-sms.ftl'
      url: {
        registrationAction: string
      }
      code: string
    }
  | {
      pageId: 'irma-transition-verify-email.ftl'
      url: {
        registrationAction: string
      }
      VERIFY_EMAIL_LINK?: string
    }
  | {
      pageId: 'phone-number-change.ftl'
      url: {
        loginAction: string
      }
      redirect_uri: string
    }
  | {
      pageId: 'phone-number-verify.ftl'
      url: {
        loginAction: string
      }
      code: string
      redirect_uri: string
    }
  | {
      pageId: 'email-change.ftl'
      url: {
        loginAction: string
      }
      email: string
      redirect_uri: string
    }
  | {
      pageId: 'email-verify.ftl'
      url: {
        loginAction: string
      }
      code: string
      redirect_uri: string
    }
  | {
      pageId: 'password-change.ftl'
      url: {
        loginAction: string
      }
      has_password: boolean
      redirect_uri: string
    }
  | {
      pageId: 'account-delete.ftl'
      url: {
        loginAction: string
      }
      emailSent: boolean
      codeVerified: boolean
      code: string
      redirect_uri: string
    }
  | {
      pageId: 'account-delete-success.ftl'
      client: {
        attributes: {
          tosUri: string
          policyUri: string
        }
      }
      url: {
        loginAction: string
      }
      redirect_uri: string
    }
  | {
      pageId: 'sso-register-user.ftl'
      url: {
        registrationAction: string
      }
      formData?: {
        email?: string
        firstName?: string
        lastName?: string
        birthday?: string
        phoneNumber?: string
      }
    }
  | {
      pageId: 'sso-verify-sms.ftl'
      url: {
        loginAction: string
      }
      code: string
      redirect_uri: string
    }
  | {
      pageId: 'mgn-select-authenticator.ftl'
      auth: {
        authenticationSelections: SelectAuthenticator.AuthenticationSelection[]
      }
    }
  | {
      pageId: 'mgn-register-form.ftl'
      url: {
        registrationAction: string
      }
      auth: {
        authenticationSelections: SelectAuthenticator.AuthenticationSelection[]
      }
    }
  | {
      pageId: 'mgn-login-form.ftl'
      url: {
        loginAction: string
      }
      auth: {
        authenticationSelections: SelectAuthenticator.AuthenticationSelection[]
      }
    }
>({
  /* Uncomment to test outside keycloak, ⚠️ don't forget to run 'yarn keycloak' at least once */
  // mockPageId: 'error.ftl',
  // mockPageId: 'info.ftl',
  // mockPageId: 'irma-transition-user-profile.ftl',
  // mockPageId: 'irma-transition-verify-sms.ftl',
  // mockPageId: 'irma-transition-verify-email.ftl',
  // mockPageId: 'login.ftl',
  // mockPageId: 'login-page-expired.ftl',
  // mockPageId: 'login-reset-password.ftl',
  // mockPageId: 'login-update-password.ftl',
  // mockPageId: 'login-verify-email.ftl',
  mockPageId: 'login-verify-sms.ftl',
  // mockPageId: 'logout-confirm.ftl',
  // mockPageId: 'register-login-details.ftl',
  // mockPageId: 'register-verify-sms.ftl',
  // mockPageId: 'register-verify-email.ftl',
  // mockPageId: 'register-verify-email-again.ftl',
  // mockPageId: 'phone-number-change.ftl',
  // mockPageId: 'phone-number-verify.ftl',
  // mockPageId: 'email-change.ftl',
  // mockPageId: 'email-verify.ftl',
  // mockPageId: 'account-delete.ftl',
  // mockPageId: 'account-delete-success.ftl',
  // mockPageId: 'mgn-select-authenticator.ftl',
  // mockPageId: 'mgn-register-form.ftl',
  // mockPageId: 'mgn-login-form.ftl',
  /**
   * Customize the simulated kcContext that will let us
   * dev the page outside keycloak (with auto-reload)
   */
  mockData: [
    {
      pageId: 'login.ftl',
      message: {
        summary: 'U ontvangt binnenkort een e-mail met verdere instructies.',
        type: 'success',
      },
      redirect_uri: process.env.REACT_APP_REDIRECT_URI,
    },
    {
      pageId: 'register-login-details.ftl',
      url: {
        registrationAction: '#',
      },
      formData: {},
      client: {
        attributes: {
          tosUri:
            'https://ivido.nl/wp-content/uploads/2021/05/210201-Algemene-Voorwaarden-Ivido-gebruikers-2021-1.pdf',
        },
      },
      recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
    },
    {
      pageId: 'register-user-details.ftl',
      url: {
        registrationAction: '#',
      },
      formData: {},
    },
    {
      pageId: 'register-verify-sms.ftl',
      url: {
        registrationAction: '#',
      },
      code: '123456',
    },
    {
      pageId: 'register-verify-email.ftl',
      url: {
        registrationAction: '#',
      },
      VERIFY_EMAIL_LINK: '#',
    },
    {
      pageId: 'register-verify-email-again.ftl',
      url: {
        registrationAction: '#',
      },
      VERIFY_EMAIL_LINK: '#',
    },
    {
      pageId: 'login-verify-sms.ftl',
      url: {
        loginAction: '#',
      },
      code: '123456',
      redirect_uri: process.env.REACT_APP_REDIRECT_URI,
    },
    {
      pageId: 'login-verify-email.ftl',
      url: {
        loginAction: '#',
      },
    },
    {
      pageId: 'login-reset-password.ftl',
    },
    {
      pageId: 'login-update-password.ftl',
      url: {
        loginAction: '#',
      },
      username: 'test@home.com',
    },
    {
      pageId: 'login-page-expired.ftl',
    },
    {
      pageId: 'logout-confirm.ftl',
    },
    {
      pageId: 'irma-transition-user-profile.ftl',
      url: {
        registrationAction: '#',
      },
      client: {
        attributes: {
          tosUri:
            'https://ivido.nl/wp-content/uploads/2021/05/210201-Algemene-Voorwaarden-Ivido-gebruikers-2021-1.pdf',
        },
      },
      irma_email: 'test2@test.nl',
      irma_firstName: 'John',
      irma_lastName: 'Doe',
      irma_phoneNumber: '+31612345678',
      irma_birthday: '1988-02-01',
    },
    {
      pageId: 'irma-transition-verify-sms.ftl',
      url: {
        registrationAction: '#',
      },
      code: '123456',
    },
    {
      pageId: 'irma-transition-verify-email.ftl',
      url: {
        registrationAction: '#',
      },
      VERIFY_EMAIL_LINK: '#',
    },
    {
      pageId: 'phone-number-change.ftl',
      url: {
        loginAction: '#',
      },
    },
    {
      pageId: 'phone-number-verify.ftl',
      url: {
        loginAction: '#',
      },
      code: '123456',
    },
    {
      pageId: 'email-change.ftl',
      url: {
        loginAction: '#',
      },
    },
    {
      pageId: 'email-verify.ftl',
      url: {
        loginAction: '#',
      },
      code: '123456',
    },
    {
      pageId: 'password-change.ftl',
      url: {
        loginAction: '#',
      },
      has_password: false,
    },
    {
      pageId: 'account-delete.ftl',
      url: {
        loginAction: '#',
      },
      code: '123456',
    },
    {
      pageId: 'account-delete-success.ftl',
      client: {
        attributes: {
          tosUri:
            'https://ivido.nl/wp-content/uploads/2021/05/210201-Algemene-Voorwaarden-Ivido-gebruikers-2021-1.pdf',
        },
      },
      url: {
        loginAction: '#',
      },
    },
    {
      pageId: 'sso-register-user.ftl',
      url: {
        registrationAction: '#',
      },
      formData: {},
    },
    {
      pageId: 'sso-verify-sms.ftl',
      url: {
        loginAction: '#',
      },
      code: '123456',
    },
    {
      pageId: 'mgn-select-authenticator.ftl',
      auth: {
        authenticationSelections: [
          {
            authExecId: '4d6ec0ee-a876-4941-9900-a878bf90ffb7',
            authenticationExecution: {
              authenticator: 'sso-skippable-login-form',
            },
          },
          {
            authExecId: '9d6ec0ee-a876-4941-9900-a878bf90ffb7',
            authenticationExecution: {
              authenticator: 'select-authenticator',
            },
          },
          {
            authExecId: '5d6ec0ee-a876-4941-9900-a878bf90ffb7',
            authenticationExecution: {
              authenticator: 'sso-registration-form',
            },
          },
        ],
      },
    },
    {
      pageId: 'mgn-register-form.ftl',
      url: {
        loginUrl: '#',
      },
      auth: {
        authenticationSelections: [
          {
            authExecId: '4d6ec0ee-a876-4941-9900-a878bf90ffb7',
            authenticationExecution: {
              authenticator: 'sso-skippable-login-form',
            },
          },
          {
            authExecId: '9d6ec0ee-a876-4941-9900-a878bf90ffb7',
            authenticationExecution: {
              authenticator: 'select-authenticator',
            },
          },
          {
            authExecId: '5d6ec0ee-a876-4941-9900-a878bf90ffb7',
            authenticationExecution: {
              authenticator: 'sso-registration-form',
            },
          },
        ],
      },
    },
    {
      pageId: 'mgn-login-form.ftl',
      url: {
        loginAction: '#',
      },
      auth: {
        authenticationSelections: [
          {
            authExecId: '4d6ec0ee-a876-4941-9900-a878bf90ffb7',
            authenticationExecution: {
              authenticator: 'sso-skippable-login-form',
            },
          },
          {
            authExecId: '9d6ec0ee-a876-4941-9900-a878bf90ffb7',
            authenticationExecution: {
              authenticator: 'select-authenticator',
            },
          },
          {
            authExecId: '5d6ec0ee-a876-4941-9900-a878bf90ffb7',
            authenticationExecution: {
              authenticator: 'sso-registration-form',
            },
          },
        ],
      },
    },
  ],
})

export type KcContext = NonNullable<typeof kcContext>
